<script setup lang="ts">
import { useLinks } from "../composables/useLinks";

// eslint-disable-next-line no-undef
const props = defineProps<{
  id: string;
  url: string;
  email?: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
  target?: string;
}>();

const { preparedUrl, trackClickEvent } = useLinks(props);

</script>

<template>
  <NuxtLink
    v-if="linktype === 'story'"
    :key="id"
    :to="preparedUrl"
    class="hover:underline cursor-pointer"
    :target="target"
    v-bind="$attrs"
    @click="trackClickEvent"
    ><slot></slot
  ></NuxtLink>
  <a
    v-else
    :href="preparedUrl"
    class="hover:underline cursor-pointer"
    :target="target"
    v-bind="$attrs"
    @click="trackClickEvent"
  >
    <slot></slot>
  </a>
</template>
